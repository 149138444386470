.mainPic {
  position: relative;
  height: 100vh;
  width: 100vw;
  font-weight: 900;
  font-family: Georgia-Bold, Georgia;
}

img {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.dataTitle {
  display: flex;
  position: absolute;
  width: 100%;
  height: 88px;
  font-size: 70px;
  justify-content: center;
  align-items: center;
  background-color: yellow;
}

.yellow {
  background-color: yellow;
}
