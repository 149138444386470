body {
  font-size: 14pt;
  line-height: 107%;
  font-family: Georgia, 'Times New Roman', Times, serif;
  position: relative;
  margin: 0;
  padding: 0;
  /* background-color: black; */
}

h6 {
  font-size: 10pt;
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-style: italic;
}

h4 {
  margin-top: 5px;
}

.callCommon {
  margin: 3px;
  padding: 3px;
}

.divZero {
  margin: 0px;
  padding: 0px;
}

.header {
  border-radius: 10px 10px 0 0;
  background-color: dodgerblue;
  height: 20px;
}

.Footer {
  background-color: dodgerblue;
  border-radius: 0 0 10px 10px;
  height: 20px;
}

.statTable {
  font-size: 10pt;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 450px;
}

.absolutely-centered {
  position: absolute;
  height: 90vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

progress {
  height: 22px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2vw;
}

.operCommon {
  background-color: lightslategray;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.operTextArea {
  margin: 15px;
}

.operTable {
  font-size: 12pt;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 450px;
  border-collapse: collapse;
}

.operRow {
  border: 1px solid gray;
  height: 20px;
}

.operCell {
  padding: 4px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.lightGray {
  color: lightgray;
}

audio {
  margin-top: 20px;
}

.retreatText {
  margin: 20px 0 20px 0;
}

.retreat-block {
  margin: 0 0 50px 0;
}

.alignCenter {
  text-align: center;
}
